body {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
    background-color: #000916;
    /* background-image: radial-gradient(85% 85% at 50% 50%, hsla(185, 100%, 25%, 0.25) 0%, hsla(185, 100%, 25%, 0.12) 50%, hsla(185, 100%, 25%, 0) 100%) */
}

.code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.margin-left {
    margin-left: 1rem;
}

.margin-right {
    margin-right: 1rem;
}

.margin-top {
    margin-top: 1rem;
}

.margin-top-lg {
    margin-top: 2rem;
}

.margin-bottom {
    margin-bottom: 1rem;
}

.padding-left {
    padding-left: 1rem;
}

.padding-right {
    padding-right: 1rem;
}

.padding-top {
    padding-top: 1rem;
}

.padding-bottom {
    padding-bottom: 1rem;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-justify {
    text-align: justify;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-lowercase {
    text-transform: lowercase;
}

.text-capitalize {
    text-transform: capitalize;
}

.text-bold {
    font-weight: bold;
}

.text-italic {
    font-style: italic;
}

.text-normal {
    font-weight: normal;
}

.text-underline {
    text-decoration: underline;
}

.text-line-through {
    text-decoration: line-through;
}

.text-no-decoration {
    text-decoration: none;
}

.text-nowrap {
    white-space: nowrap;
}

.text-break {
    word-break: break-all;
}

.text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.text-shadow {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

.text-clip {
    background: linear-gradient(90deg, #f00, #ff0, #0f0, #0ff, #00f, #f0f, #f00);
    background-size: 200% 200%;
    background-clip: text;
    color: transparent;
    animation: gradient 5s linear infinite;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    100% {
        background-position: 100% 50%;
    }
}

.flex {
    display: flex;
}

.flex-column {
    flex-direction: column;
}

.flex-row {
    flex-direction: row;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-nowrap {
    flex-wrap: nowrap;
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse;
}

.justify-start {
    justify-content: start;
}

.justify-end {
    justify-content: end;
}

.justify-center {
    justify-content: center;
}

.justify-between {
    justify-content: space-between;
}

.justify-around {
    justify-content: space-around;
}

.justify-evenly {
    justify-content: space-evenly;
}

.align-start {
    align-items: flex-start;
}

.align-end {
    align-items: flex-end;
}

.align-center {
    align-items: center;
}

.align-baseline {
    align-items: baseline;
}

.align-stretch {
    align-items: stretch;
}

.align-content-start {
    align-content: flex-start;
}

.align-content-end {
    align-content: flex-end;
}

.align-content-center {
    align-content: center;
}

.align-content-between {
    align-content: space-between;
}

.align-content-around {
    align-content: space-around;
}

.align-content-stretch {
    align-content: stretch;
}

.align-self-start {
    align-self: flex-start;
}

.align-self-end {
    align-self: flex-end;
}

.align-self-center {
    align-self: center;
}

.align-self-baseline {
    align-self: baseline;
}

.full-width {
    width: 1vw;
}

.full-height {
    height: 1vh;
}

.full-size {
    width: 1vw;
    height: 1vh;
}

.absolute {
    position: absolute;
}

.center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 3rem;
    text-align: center;
    z-index: 150;
}

.letter-spacing {
    letter-spacing: 0.1rem;
}
.gap {
    gap: 1rem;
}

.cursor {
    cursor: pointer;
}

.gap-2x {
    gap: 2rem;
}

.gap-sm {
    gap: 0.6rem;
}