.homeMenu {
    color: #ffff80;
    font-size: 1rem;
}

.text-yellow {
    color: #ffff80;
}

[data-name=bg] {
    /* color: hsl(60, 75%, 10%) */
    color: rgba(0, 0, 0, 0);
}

[data-name=line] {
    color: hsl(60, 75%, 50%)
}

.text-normal {
    font-weight: normal;
    font-size: 1rem;
}

.menu-item {
    /* position: 'relative',
        width: 100,
        height: 40 */
    position: relative;
    width: 130px;
    height: 20px;
}